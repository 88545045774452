@use "sass:meta" as ---lbhi7p8q6p;.switch_language {

}

.ccm-block-switch-language {
    opacity: .5;
}

.ccm-block-switch-language:hover,
.ccm-block-switch-language-active-flag {
    opacity: 1;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5538");