@use "sass:meta" as ---lbhi7p8q6p;// Page banner
.page-banner,
.page-banner-content {
    height: $page-banner-height;
}

.ccm-toolbar-visible .page-banner {
    top: 48px !important;
}

.page-banner {
    background: $gray-300;
}

.page-banner-content {

}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5521");