@use "sass:meta" as ---lbhi7p8q6p;.scrolled .hamburger-inner,
.scrolled .hamburger-inner::before,
.scrolled .hamburger-inner::after {
    background: $hamburger-layer-scrolled-color;
}

.scrolled .hamburger.is-active .hamburger-inner,
.scrolled .hamburger.is-active .hamburger-inner::before,
.scrolled .hamburger.is-active .hamburger-inner::after {
    background: $hamburger-layer-scrolled-color;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5512");