@use "sass:meta" as ---lbhi7p8q6p;.back-to-top {
    width: 40px;
    height: 40px;
    font-size: 20px;
    background: darken($primary, 15%);
    color: #fff;
    right: var(--back-to-top-side-push, calc($grid-gutter-width / 2));
    bottom: calc($grid-gutter-width / 2);
    z-index: $zindex-fixed + 1;
    opacity: 0;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), margin-right 0s;
}

.back-to-top:hover {
    background: $secondary;
}

.back-to-top-icon {
    top: -1px;
}

// Show button when page is scrolled
.scrolled .back-to-top {
    opacity: 1;
    pointer-events: all;
}

// Element was moved to the left to not cover logo
.back-to-top.moved-to-side {
    transform: translateY(-50px);
    bottom: var(--back-to-top-bottom-push);
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5526");