@use "sass:meta" as ---lbhi7p8q6p;// Common section styles
.weiss-padding,
.grau-100-padding,
.grau-200-padding,
.grau-300-padding,
.akzentfarbe-1-padding,
.akzentfarbe-2-padding,
.akzentfarbe-3-padding,
.akzentfarbe-4-padding,
.akzentfarbe-5-padding,
.rahmenlinie-unten-padding
{
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-top: $spacer * 3;
        padding-bottom: $spacer * 3;
    }

    @include media-breakpoint-up(xl) {
        padding-top: $spacer * 3;
        padding-bottom: $spacer * 3;
    }
}
h1 {
    color: $primary;
}

.weiss, .weiss-padding, .rahmenlinie-unten-padding {
    background-color: $white;
}

.grau-100, .grau-100-padding {
    background-color: $gray-100;
}

.grau-200, .grau-200-padding {
    background-color: $gray-200;
}

.grau-300, .grau-300-padding {
    background-color: $gray-300;
}

.akzentfarbe-1, .akzentfarbe-1-padding {
    background-color: $primary;
}

.akzentfarbe-2, .akzentfarbe-2-padding {
    background-color: $secondary;
}

.akzentfarbe-3, .akzentfarbe-3-padding {
    background-color: $tertiary;
}

.aktzenfarbe-4, .akzentfarbe-4-padding {
    background-color: $quaternary;
}

.akzentfarbe-5, .akzentfarbe-5-padding {
    background-color: $quinary;
}

// Sections content
.akzentfarbe-1, .akzentfarbe-1-padding,
.akzentfarbe-2, .akzentfarbe-2-padding,
.akzentfarbe-3, .akzentfarbe-3-padding,
.akzentfarbe-4, .akzentfarbe-4-padding,
.akzentfarbe-5, .akzentfarbe-5-padding {
    --custom-ul-svg-color: #fff;

    color: #ffffff;
    letter-spacing: 0.3px;

    h1, h2, h3, h4, h5, h6, a, .small {
        color: #ffffff !important;
    }

    a {
        color: #ffffff !important;

        &:hover, &:focus {
            color: #ffffff !important;
        }
    }

    b, strong {
        color: #ffffff !important;
    }

    .blockquote,
    blockquote {
        background: #fff !important;
        color: $body-color !important;

        a {
            color: $body-color !important;
        }
    }

    mark,
    .mark {
        color: $body-color !important;
        background: #fff !important;
    }
}
.border-radius {
    border-radius: $border-radius;
}
.rahmenlinie-unten,
.rahmenlinie-unten-padding {
    border-bottom: 1px solid $border-color;
    position: relative;
}
.ccm-page {

    .btn-primary {
        position: relative;
        overflow: hidden;
        transition: background .3s ease-in-out;
    }

    .btn-primary:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
    }

    .btn-primary:hover:before {
        background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2230%22%20cy%3D%2220%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2215%22%20r%3D%223%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2270%22%20cy%3D%2225%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2290%22%20cy%3D%2210%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2220%22%20cy%3D%2240%22%20r%3D%223%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2240%22%20cy%3D%2250%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2260%22%20cy%3D%2245%22%20r%3D%223%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2280%22%20cy%3D%2260%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2215%22%20cy%3D%2270%22%20r%3D%223%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2235%22%20cy%3D%2280%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2255%22%20cy%3D%2275%22%20r%3D%223%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2275%22%20cy%3D%2285%22%20r%3D%222%22%20fill%3D%22white%22%2F%3E%3Ccircle%20cx%3D%2295%22%20cy%3D%2290%22%20r%3D%223%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.6;
    }
}

.ccm-page.page-type-default-contained {
    .page-image-area {
        margin-top: 9.5rem;
        @include media-breakpoint-down(lg){
            margin-top: 7.5rem;
        }
        @include media-breakpoint-down(md) {
            margin-top: 5.5rem;
        }
    }
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5506");