@use "sass:meta" as ---lbhi7p8q6p;.not-found-wrapper {
    background: #f1f1f1;
    width: 100vw;
    height: 100vh;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
}

.not-found-image-wrapper {

}

.not-found-image-wrapper,
.not-found-text-wrapper {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}

.not-found-text-wrapper {
    background: rgba(#000, .5);
    color: #fff;
}

.not-found-number {
    @include rfs(240px);
    line-height: 1;
    color: var(--bs-gray-400);
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5562");