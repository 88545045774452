@use "sass:meta" as ---lbhi7p8q6p;.main-nav .navbar-nav {
    --bs-navbar-nav-link-padding-x: #{rfs-value($desktop-menu-link-spacing-x)};
    --bs-navbar-active-color: #{$desktop-menu-link-active-color};
    --bs-nav-link-color: #{$desktop-menu-link-color};
    --bs-nav-link-hover-color: #{$desktop-menu-link-hover-color};
    --bs-nav-link-font-weight: #{$desktop-menu-link-font-weight};
    --bs-nav-link-font-size: #{rfs-value($desktop-menu-link-font-size)};
    --bs-nav-link-active-color: #{$desktop-menu-link-active-color};
    --desktop-menu-link-stripe-color: #{$desktop-menu-link-stripe-color};
    margin-right: calc(var(--bs-navbar-nav-link-padding-x) * -1);
}

.main-nav .nav-item .nav-link {
    padding: 0;
    margin: 0 var(--bs-navbar-nav-link-padding-x);
    transition: $transition-base;
    position: relative;
}

// scrolled
.scrolled .main-nav .navbar-nav {
    --bs-navbar-nav-link-padding-x: #{rfs-value($desktop-menu-link-scrolled-spacing-x)};
    --bs-navbar-active-color: #{$desktop-menu-link-scrolled-active-color};
    --bs-nav-link-color: #{$desktop-menu-link-scrolled-color};
    --bs-nav-link-hover-color: #{$desktop-menu-link-scrolled-hover-color};
    --bs-nav-link-font-weight: #{$desktop-menu-link-scrolled-font-weight};
    --bs-nav-link-font-size: #{rfs-value($desktop-menu-link-scrolled-font-size)};
    --bs-nav-link-active-color: #{$desktop-menu-link-scrolled-active-color};
    --desktop-menu-link-stripe-color: #{$desktop-menu-link-stripe-scrolled-color};
    margin-right: calc(var(--bs-navbar-nav-link-padding-x) * -1);
}

// Dropdown
.main-nav .dropdown > .dropdown-menu {
    --bs-dropdown-padding-y: #{$desktop-menu-dropdown-padding-y};
    --bs-dropdown-spacer: #{$desktop-menu-dropdown-spacer};

    --bs-dropdown-border-color: #{$desktop-menu-dropdown-border-color};
    --bs-dropdown-border-width: #{$desktop-menu-dropdown-border-width};
    --bs-dropdown-border-radius: #{$desktop-menu-dropdown-border-radius};

    --bs-dropdown-item-padding-x: #{$desktop-menu-dropdown-item-padding-x};
    --bs-dropdown-item-padding-y: #{$desktop-menu-dropdown-item-padding-y};

    --bs-dropdown-divider-margin-y: #{$desktop-menu-divider-margin-y};
    --bs-dropdown-divider-margin-x: #{$desktop-menu-divider-margin-x};
    --bs-dropdown-divider-bg: #{$desktop-menu-dropdown-divider-bg};

    --bs-dropdown-bg: #{$desktop-menu-dropdown-bg};
    --bs-dropdown-font-size: #{rfs-value($desktop-menu-dropdown-font-size)};

    --bs-dropdown-link-color: #{$desktop-menu-dropdown-link-color};
    --bs-dropdown-link-hover-color: #{$desktop-menu-dropdown-link-hover-color};
    --bs-dropdown-link-hover-bg: #{$desktop-menu-dropdown-link-hover-bg};
    --bs-dropdown-link-active-color: #{$desktop-menu-dropdown-link-active-color};
    --bs-dropdown-link-active-bg: #{$desktop-menu-dropdown-link-active-bg};

    left: var(--bs-navbar-nav-link-padding-x);
    margin-top: var(--bs-dropdown-spacer);
}

.main-nav .dropdown > .dropdown-menu .nav-link {
    padding-top: 5px;
    padding-bottom: 5px;
}

.main-nav .dropdown .dropdown-divider {
    margin: var(--bs-dropdown-divider-margin-y) var(--bs-dropdown-divider-margin-x);
    display: none;
}

@if ($desktop-menu-dropdown-dividers) {
    .main-nav .dropdown .dropdown-divider {
        display: block;
    }
}

// Hover and active styles
@if ($enable-desktop-menu-link-stripe) {
    .main-nav .main-nav-level-1 > .nav-item > .nav-link:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        height: 2px;
        background: var(--desktop-menu-link-stripe-color);
        transform: scaleX(0);
        transition: $transition-base;
    }

    .main-nav .main-nav-level-1 > .nav-item.active > .nav-link:before,
    .main-nav .main-nav-level-1 > .nav-item.nav-path-selected > .nav-link:before,
    .main-nav .main-nav-level-1 > .nav-item > .nav-link:hover:before {
        transform: scaleX(1);
    }

    .main-nav:hover .main-nav-level-1 > .nav-item.active:not(:hover) > .nav-link:before,
    .main-nav:hover .main-nav-level-1 > .nav-item.nav-path-selected:not(:hover) > .nav-link:before {
        transform: scaleX(0);
    }
}

.main-nav:hover .main-nav-level-1 > .nav-item.active:not(:hover) > .nav-link {
    color: var(--bs-nav-link-color);
}

// Dropdown animation
.main-nav .dropdown > .dropdown-menu {
    display: block;
    opacity: 0;
    transform: translate3d(0, 30px, 0);
    pointer-events: none;
    transition: $transition-base;
    transform-origin: top;
}

.main-nav .dropdown > .dropdown-menu.show {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5534");