@use "sass:meta" as ---lbhi7p8q6p;.textbox {
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;

        .textbox-rechts {
            padding-left: $spacer * 4;
        }

        .textbox-links {
            padding-right: $spacer * 4;
            text-align: right;
        }

        .textbox-rechts, .textbox-links {
            h3 {
                margin-bottom: 0.5rem;
            }
        }
    }
}

.textbox {
    .textbox-links, .textbox-rechts {
        @include media-breakpoint-down(lg) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5507");