@use "sass:meta" as ---lbhi7p8q6p;.offcanvas-main-nav {
    --bs-offcanvas-padding-x: #{rfs-value($mobile-menu-padding-x)};
    --bs-offcanvas-padding-y: #{rfs-value($mobile-menu-padding-y)};
    --bs-offcanvas-color: #{$mobile-menu-color};
    --bs-offcanvas-bg: #{$mobile-menu-bg};
    --bs-offcanvas-border-width: 0;
    --bs-offcanvas-title-line-height: #{$mobile-menu-title-line-height};
    --bs-nav-link-font-size: #{rfs-value($mobile-menu-nav-link-font-size)};
}

.offcanvas-main-nav .offcanvas-header {
    padding-left: rfs-value($mobile-menu-nav-link-padding-x);
    border-bottom: $mobile-menu-header-border;
}

.offcanvas-main-nav .offcanvas-body {
    padding-left: 0;
    padding-right: 0;

    @if ($mobile-menu-enable-borders) {
        padding-top: 0;
    }
}

.offcanvas-main-nav .main-nav-level-1 > .nav-item > .nav-link {
    padding: rfs-value($mobile-menu-nav-link-padding-y $mobile-menu-nav-link-padding-x);
    text-transform: $mobile-menu-nav-link-text-transform;

    @if ($mobile-menu-enable-borders) {
        border-bottom: $mobile-menu-nav-link-border;
    }
}

.offcanvas-main-nav .dropdown-toggle.show {
    background: $primary;
    color: #fff;
    border-color: $primary;
}

.offcanvas-main-nav .dropdown-toggle:after {
    margin-left: 0.55em;
}

.offcanvas-main-nav .btn-close {
    background: #{ escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$mobile-menu-btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")) } center/1em auto no-repeat;
}

.offcanvas-main-nav .multilingual-area .ccm-region-flag {
    width: 24px;
    height: auto;
    margin-left: 8px;
    margin-right: 8px;
}

.offcanvas-main-nav .dropdown-menu {
    --bs-dropdown-padding-y: 0;
    --bs-dropdown-bg: #{$mobile-menu-dropdown-bg};
    --bs-dropdown-font-size: #{rfs-value($mobile-menu-dropdown-font-size)};
    border: 0;
}

.offcanvas-main-nav .dropdown-menu.show {
    position: static !important;
    transform: none !important;
}

.offcanvas-main-nav .dropdown-toggle {
    white-space: normal;
}

.offcanvas-main-nav .dropdown-item {
    --bs-dropdown-bg: #{$mobile-menu-dropdown-bg};
    --bs-dropdown-link-color: #{$mobile-menu-dropdown-link-color};
    --bs-dropdown-link-hover-color: #{$mobile-menu-dropdown-link-hover-color};
    --bs-dropdown-link-hover-bg: #{$mobile-menu-dropdown-link-hover-bg};
    --bs-dropdown-link-active-color: #{$mobile-menu-dropdown-link-active-color};
    --bs-dropdown-link-active-bg: #{$mobile-menu-dropdown-link-active-bg};
    --bs-dropdown-item-padding-x: #{rfs-value($mobile-menu-dropdown-item-padding-x)};
    --bs-dropdown-item-padding-y: #{rfs-value($mobile-menu-dropdown-item-padding-y)};

    text-transform: $mobile-menu-dropdown-link-text-transform;

    @if ($mobile-menu-enable-borders) {
        border-bottom: $mobile-menu-dropdown-link-border;
    }
}

.offcanvas-main-nav .dropdown .dropdown-divider {
    display: none !important;
}

//
// Additional blocks
//
.offcanvas-social-links .ccm-block-social-links a {
    color: $mobile-menu-color;
    width: rfs-value(30px);
    height: rfs-value(30px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.offcanvas-social-links .ccm-block-social-links li:last-child {
    margin-right: 0;
}

.offcanvas-multilingual .multilingual-area {
    display: flex;
    justify-content: center;
}

.ccm-page .offcanvas-multilingual .switch-language .switch-language-current,
.ccm-page .offcanvas-multilingual .switch-language .switch-language-current:hover,
.ccm-page .offcanvas-multilingual .switch-language.is-open .switch-language-current {
    background: var(--bs-offcanvas-bg);
    color: var(--bs-offcanvas-color);
    border: 1px solid #{$mobile-menu-border-color};
}

.offcanvas-multilingual .switch-language .switch-language-option {
    background: var(--bs-offcanvas-bg);
    color: var(--bs-offcanvas-color);
    border: 1px solid #{$mobile-menu-border-color};
    border-top: 0;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5535");