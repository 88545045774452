@use "sass:meta" as ---lbhi7p8q6p;.sidebar-nav {
    background: $gray-100;
    padding-top: 20px;
    padding-bottom: 15px;
}

.sidebar-nav-heading {
    padding: 0 25px;
}

.sidebar-nav .nav-link {
    --bs-nav-link-padding-y: 10px;
    --bs-nav-link-padding-x: 25px;
    --bs-nav-link-color: var(--bs-body-color);
    --bs-nav-link-hover-color: var(--bs-body-color);
}

.sidebar-nav .nav-link {
}

.sidebar-nav .nav-link:hover {
    background: $gray-300;
}

.sidebar-nav .nav-path-selected {
    background: $gray-200;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5537");