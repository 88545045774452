@use "sass:meta" as ---lbhi7p8q6p;.fullscreen-menu-wrapper {
    --bs-offcanvas-zindex: #{$fullscreen-menu-zindex};
    --bs-offcanvas-width: #{$fullscreen-menu-horizontal-width};
    --bs-offcanvas-height: #{$fullscreen-menu-vertical-height};
    --bs-offcanvas-padding-x: #{rfs-value($fullscreen-menu-padding-x)};
    --bs-offcanvas-padding-y: #{rfs-value($fullscreen-menu-padding-y)};
    --bs-offcanvas-color: #{$fullscreen-menu-color};
    --bs-offcanvas-bg: #{$fullscreen-menu-bg};
    --bs-offcanvas-border-width: #{$fullscreen-menu-border-width};
    --bs-offcanvas-border-color: #{$fullscreen-menu-border-color};
    --bs-offcanvas-box-shadow: #{$fullscreen-menu-box-shadow};

    --fullscreen-menu-spacing-y: #{rfs-value($fullscreen-menu-spacing-y)};
    --fullscreen-menu-space-between-columns-x: #{rfs-value($fullscreen-menu-space-between-columns-x)};
    --fullscreen-menu-space-between-columns-y: #{rfs-value($fullscreen-menu-space-between-columns-y)};
    --fullscreen-backdrop-filter: #{rfs-value($fullscreen-backdrop-filter)};

    @if ($fullscreen-enable-backdrop-filter) {
        backdrop-filter: var(--fullscreen-backdrop-filter);
    }
}

.fullscreen-menu-btn-close {
    position: absolute;
    right: 5vw;
    top: 5vw;
    background: transparent escape-svg($fullscreen-menu-btn-close-bg) center / $fullscreen-menu-btn-close-size auto no-repeat;
    width: $fullscreen-menu-btn-close-size;
    height: $fullscreen-menu-btn-close-size;
}

.fullscreen-menu {
    height: var(--bs-offcanvas-height);
    padding: var(--fullscreen-menu-spacing-y) 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
}

// Dropdown for fullscreen nav
.fullscreen-menu .dropdown-menu {
    --bs-dropdown-color: #{$fullscreen-menu-nav-link-color};
    --bs-dropdown-bg: transparent;
    --bs-dropdown-link-color: #{$fullscreen-menu-nav-link-color};
    --bs-dropdown-border-width: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-font-size: #{rfs-value($fullscreen-menu-link-font-size)};

    --bs-dropdown-link-hover-bg: transparent;
    --bs-dropdown-link-active-bg: transparent;
    --bs-dropdown-link-hover-color: #{$fullscreen-menu-primary};
    --bs-dropdown-link-active-color: #{$fullscreen-menu-primary};

    padding-bottom: 0;
    transform: none !important;
    position: relative !important;
}

// Dropdown
.fullscreen-menu .nav-item .dropdown {
    position: relative;
    width: fit-content;
}

.fullscreen-menu .has-dropdown-toggle:after {
    content: '';
    display: inline-block;
    width: 8px;
}

.fullscreen-menu .nav-item .dropdown-toggle {
    border: 0 !important;
    background: $fullscreen-menu-dropdown-toggle-bg;
    margin-top: 10px;
    padding: 9px 6px 9px 6px;

    @include media-breakpoint-up(lg) {
        padding: 14px 8px 14px 8px;
    }
}

.fullscreen-menu .nav-item .dropdown-toggle:hover {
    background: $fullscreen-menu-dropdown-toggle-hover-bg;
}

.fullscreen-menu .nav-item .dropdown-toggle:after {
    margin-left: 0;
    border-top-color: $fullscreen-menu-dropdown-toggle-triangle-color;
    transition: $transition-base;
}

.fullscreen-menu .nav-item .dropdown-toggle:hover:after {
    border-top-color: $fullscreen-menu-dropdown-toggle-triangle-hover-color;
}

// Depending on which header layout is used, we are using different breakpoints
[data-header-layout='logo_left'] .fullscreen-menu .dropdown-menu {
    @include media-breakpoint-up($header-logo-left-breakpoint) {
        --bs-dropdown-font-size: #{rfs-value($fullscreen-menu-link-font-size-large * 0.78)};
    }
}

[data-header-layout='logo_centered'] .fullscreen-menu .dropdown-menu {
    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        --bs-dropdown-font-size: #{rfs-value($fullscreen-menu-link-font-size-large * 0.78)};
    }
}

.fullscreen-menu .dropdown-toggle::after {
    margin-left: 0.5em;
}

// Depending on which header layout is used, we are using different breakpoints
[data-header-layout='logo_left'] .fullscreen-menu {
    @include media-breakpoint-up($header-logo-left-breakpoint) {
        padding: calc(var(--fullscreen-menu-spacing-y) * 2) 0;
    }
}

[data-header-layout='logo_centered'] .fullscreen-menu {
    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        padding: calc(var(--fullscreen-menu-spacing-y) * 2) 0;
    }
}

.fullscreen-menu-content {
    --bs-gutter-x: var(--fullscreen-menu-space-between-columns-x);

    max-width: calc(#{map-get($container-max-widths, xxl)} + var(--fullscreen-menu-space-between-columns-x));
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
}

.fullscreen-menu-row {
    --bs-gutter-x: var(--fullscreen-menu-space-between-columns-x);
}

.fullscreen-menu-col {
    margin-bottom: var(--fullscreen-menu-space-between-columns-y);

    //@include media-breakpoint-up(lg) {
    //    margin-bottom: 0;
    //}
}

.fullscreen-menu-col-area > *:last-child {
    margin-bottom: 0;
}

.fullscreen-menu-col .fullscreen-menu-content-area-2 {
}

// Fullscreen menu nav
.fullscreen-menu-wrapper .navbar-nav {
    --bs-nav-link-font-size: #{rfs-value($fullscreen-menu-link-font-size)};
    --bs-nav-link-color: #{$fullscreen-menu-nav-link-color};
    --bs-nav-link-hover-color: #{$fullscreen-menu-nav-link-hover-color};
    --bs-nav-link-active-color: #{$fullscreen-menu-nav-link-active-color};
    --bs-nav-link-font-weight: #{$fullscreen-menu-nav-link-font-weight};
    --bs-nav-link-padding-y: #{rfs-value($fullscreen-menu-nav-link-padding-y)};
}

// Depending on which header layout is used, we are using different breakpoints
[data-header-layout='logo_left'] .fullscreen-menu-wrapper .navbar-nav {
    @include media-breakpoint-up($header-logo-left-breakpoint) {
        --bs-nav-link-font-size: #{rfs-value($fullscreen-menu-link-font-size-large)};
        --bs-nav-link-padding-y: #{rfs-value($fullscreen-menu-nav-link-padding-y-large)};
    }
}

[data-header-layout='logo_centered'] .fullscreen-menu-wrapper .navbar-nav {
    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        --bs-nav-link-font-size: #{rfs-value($fullscreen-menu-link-font-size-large)};
        --bs-nav-link-padding-y: #{rfs-value($fullscreen-menu-nav-link-padding-y-large)};
    }
}

.fullscreen-nav + .fullscreen-nav {
    margin-top: 50px;
}

.fullscreen-menu-wrapper .fullscreen-nav-level-1 > .nav-item > .nav-link {
    padding: 0;
    margin: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x) 0;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.fullscreen-menu-wrapper .fullscreen-nav-level-1 > .nav-item > .nav-link:not(:hover) {
    text-decoration-color: transparent;
}

.fullscreen-menu-wrapper .fullscreen-nav-level-1 > .nav-item:first-child > .nav-link {
    padding-top: 0;
}

// Hover and active styles
.fullscreen-menu-wrapper:hover .fullscreen-nav-level-1 > .nav-item.active:not(:hover) > .nav-link {
    color: var(--bs-nav-link-hover-color);
}

.fullscreen-menu-wrapper .email-wrapper a,
.fullscreen-menu-wrapper .website-wrapper a,
.fullscreen-menu-wrapper .phone-wrapper a {
    color: $fullscreen-menu-nav-link-color;
    text-decoration: none;
}

//
// Edit mode
//
.ccm-edit-mode .fullscreen-menu-wrapper {
    position: static !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
}

.ccm-edit-mode .fullscreen-menu-btn-close {
    display: none !important;
}

.ccm-edit-mode .hamburger-area {
    display: none !important;
}

//
// Additional blocks
//
.fullscreen-menu-wrapper .ccm-block-social-links li:last-child {
    margin-right: 0;
}

// Depending on which header layout is used, we are using different breakpoints
[data-header-layout='logo_left'] .fullscreen-menu-wrapper .social-area {
    @include media-breakpoint-up($header-logo-left-breakpoint) {
        display: none !important;
    }
}

[data-header-layout='logo_centered'] .fullscreen-menu-wrapper .social-area {
    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: none !important;
    }
}

// Depending on which header layout is used, we are using different breakpoints
[data-header-layout='logo_left'] .fullscreen-menu-wrapper .multilingual-area {
    @include media-breakpoint-up($header-logo-left-breakpoint) {
        display: none !important;
    }
}

[data-header-layout='logo_centered'] .fullscreen-menu-wrapper .multilingual-area {
    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: none !important;
    }
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5541");