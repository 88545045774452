@use "sass:meta" as ---lbhi7p8q6p;// Download css code and fonts from:
// https://gwfh.mranftl.com/fonts

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../fonts/OpenSans/open-sans-regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../../fonts/OpenSans/open-sans-bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Covered By Your Grace';
    src: url(../../fonts/CoveredByYourGrace/CoveredByYourGrace.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url(../../fonts/Poppins/Poppins-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url(../../fonts/Poppins/Poppins-Bold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5503");