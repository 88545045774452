@use "sass:meta" as ---lbhi7p8q6p;@if $fullscreen-menu-animation == 'slide-down' {
    .fullscreen-menu-wrapper {
        top: 0;
        right: 0;
        left: 0;
        transform: translateY(-100%);
    }
}

@if $fullscreen-menu-animation == 'slide-up' {
    .fullscreen-menu-wrapper {
        right: 0;
        left: 0;
        transform: translateY(100%);
    }
}

@if $fullscreen-menu-animation == 'slide-right' {
    .fullscreen-menu-wrapper {
        top: 0;
        left: 0;
        transform: translateX(-100%);
        width: var(--bs-offcanvas-width);
    }
}

@if $fullscreen-menu-animation == 'slide-left' {
    .fullscreen-menu-wrapper {
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: var(--bs-offcanvas-width);
    }
}

@if $fullscreen-menu-animation == 'zoom-in' {
    .fullscreen-menu-wrapper {
        top: 0;
        right: 0;
        transform: scale(.5);
        width: var(--bs-offcanvas-width);
    }
}

@if $fullscreen-menu-animation == 'zoom-out' {
    .fullscreen-menu-wrapper {
        top: 0;
        right: 0;
        transform: scale(3);
        width: var(--bs-offcanvas-width);
    }
}

@if $fullscreen-menu-animation == 'spin' {
    .fullscreen-menu-wrapper {
        top: 0;
        right: 0;
        transform: scale(0) rotate(360deg);
        width: var(--bs-offcanvas-width);
    }
}

//
// Open/close default animation
//
.fullscreen-menu-default-animation {
    transition: transform .4s ease-in-out, opacity .4s;
    opacity: $fullscreen-menu-animation-opacity;
}

.fullscreen-menu-default-animation.showing,
.fullscreen-menu-default-animation.show {
    opacity: 1;
}

.fullscreen-menu-default-animation.hiding {
    opacity: $fullscreen-menu-animation-opacity;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5542");