@use "sass:meta" as ---lbhi7p8q6p;.hero,
.hero-content {
    height: $hero-height;
}

.ccm-toolbar-visible .hero {
    top: 48px !important;
}

.hero {
    background: $gray-300;
}

.hero-content {
    padding: 0 20px;
}

.hero-text {
    .h1, .h2, .h3, .h4,
    h1, h2, h3, h4, p {
        color: #fff;
        text-shadow: -1px 1px 1px #000;
    }
}

.hero-content h1 {
    margin-bottom: 0;
    font-weight: $font-weight-semibold;
    font-size: rfs-value(44px);
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: rfs-value(90px);
    }
}

.hero-content h4 {
    font-family: $font-family-secondary;

    @include media-breakpoint-up(lg) {
        font-size: rfs-value(40px);
    }
}

.hero-content p {
    font-size: rfs-value(22px);
    &:last-of-type {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        font-size: rfs-value(32px);
    }
}

.hero-scroll-down {
    position: absolute;
    z-index: 60;
    bottom: 2vh;
    width: 100%;
    text-align: center;
    height: 100px;
}

.hero-scroll-down-button {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: rfs-value(64px);
    color: rgba(#fff, 1);
    outline: 0;
    animation: up-down 2s infinite linear;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5520");