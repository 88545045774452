@use "sass:meta" as ---lbhi7p8q6p;.ccm-page a {
    transition: $transition-base;
}

.ccm-page a[href^='tel:'] {
    pointer-events: none;
    @include media-breakpoint-down(lg) {
        color: $primary !important;
        pointer-events: auto;
    }
}

.ccm-page b,
.ccm-page strong {
    font-weight: $font-weight-bold;
}

::selection {
    background: darken($primary,10%);
    color: $white;
}

.mark {
    color: $mark-color;
}

.ccm-page p:last-of-type {
    margin-bottom: 0 !important;
}

.blockquote,
blockquote {
    padding: rfs-value(30px);
    background: $gray-100;
    margin: $blockquote-margin-y 0;
    @include font-size($blockquote-font-size);
    position: relative;

    @include media-breakpoint-up(md) {
        padding: rfs-value(40px 90px 40px 90px);
    }
}

blockquote > :last-child {
    margin-bottom: 0;
}

blockquote:before,
.blockquote:before {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: $font-awesome;
    font-weight: 900;
    content: '\f10d';
    display: block;
    font-size: rfs-value(33px);
    color: $gray-400;
    margin-bottom: rfs-value(15px);
    height: 1em;

    @include media-breakpoint-up(md) {
        position: absolute;
        left: rfs-value(33px);
        top: rfs-value(30px);
        margin-bottom: 0;
    }
}

.ccm-page ul.custom-ul {
    @extend %custom-ul;
}

.ccm-page ul.custom-ul-arrow {
    @extend %custom-ul-arrow;
}

.ccm-page ul.custom-ul-dot {
    @extend %custom-ul-dot;
}

.ccm-page ul.custom-ul-check {
    @extend %custom-ul-check;
}

.ccm-page ul.custom-ul-dash {
    @extend %custom-ul-dash;
}

.ccm-page ol.custom-ol {
    @extend %custom-ol;
}

.ccm-page .basic-transition {
    transition: $transition-base;
}

.ccm-page .img-placeholder {
    background: $gray-100;
}

.ccm-page .img-placeholder-dark {
    background: #e5e5e5;
}

.min-width-0 {
    min-width: 0;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5504");