@use "sass:meta" as ---lbhi7p8q6p;.header-top .ccm-block-social-links a {
    color: $header-social-link-color;
}

.header-top .ccm-block-social-links a:hover {
    color: $header-social-link-hover-color;
}

.scrolled .header-top .ccm-block-social-links a {
    color: $header-social-link-scrolled-color;
}

.scrolled .header-top .ccm-block-social-links a:hover {
    color: $header-social-link-scrolled-hover-color;
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5540");