@use "sass:meta" as ---lbhi7p8q6p;.footer {
    position: relative;
    background-color: $footer-bg;
    color: $footer-color;
    background-image: url('../../images/punkte.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;

    @include media-breakpoint-down(lg){
        background-image: none;

    --custom-ul-svg-color: $footer-color;

    }
}
.footer .pilze {
    img {
        max-height: 105px;
        width: auto;
        position: absolute;
        right: 24px;
        top: -50px;
        @include media-breakpoint-down(lg){
            max-height: 80px;
            top: -40px;
        }
    }
}
.footer p {
    margin-bottom: rfs-value(10px);
}

.footer h1, .footer .h1,
.footer h2, .footer .h2,
.footer h3, .footer .h3,
.footer h4, .footer .h4,
.footer h5, .footer .h5,
.footer h6, .footer .h6 {
    font-size: 1.75rem;
    line-height: 1.25;
    font-family: $font-family-secondary;
    margin-bottom: .5rem;;
}

.footer .phone-wrapper a,
.footer .email-wrapper a,
.footer a:not(.btn) {
    color: $footer-color !important;
    padding: 0;
    text-decoration: none;

    &:hover, &:focus, &:active {
        color: $footer-hover-color !important;
        background-color: transparent !important;
    }
}

.footer hr {
    margin: ($spacer * 1.5) 0;
}

.footer .btn {
    --bs-btn-color: #{$footer-color};
    --bs-btn-border-color: rgba(255, 255, 255, 0.5);
    --bs-btn-hover-color: #{$footer-hover-color};
    --bs-btn-hover-border-color: #{$footer-hover-color};
}

.footer-bottom {
    border-top: 1px solid rgba(#fff, .25);
    font-style: rfs-value(14px);
    letter-spacing: 0.3px;
}

.footer-bottom a {
    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}

;@include ---lbhi7p8q6p.load-css("sass-embedded-legacy-load-done:5519");